import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  Input,
  Button,
  Spin,
  Row,
  Col,
  Table,
  Modal,
  Descriptions,
  Tag,
  Select as SelectAntd,
} from "antd";
import Select from "@material-ui/core/Select";
import { useQuery, useMutation } from "@apollo/client";
import Genres from "../../GraphQL/queries/getGenres";
import Instruments from "../../GraphQL/queries/getInstruments";
import Voices from "../../GraphQL/queries/getVoices";
import Mods from "../../GraphQL/queries/getMods";
import Languages from "../../GraphQL/queries/getLanguage";
import getProducers from "../../GraphQL/queries/getProducers";
import GetSong from "../../GraphQL/queries/getSong";
import { useHistory, useParams } from "react-router-dom";
import UpdateSong from "../../GraphQL/mutations/updateSong";
import { UpdateSongService } from "../../Services/Songs";
import Loading from "../../Components/Loading";
import getLastsSongs from "../../GraphQL/queries/getLastsSongs";
import { useDropzone } from "react-dropzone";
import { useLocation } from 'react-router-dom';
import ReactAudioPlayer from "react-audio-player";
import { EditOutlined, SaveOutlined, LoadingOutlined } from "@ant-design/icons";

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not validate email!",
    number: "${label} is not a validate number!",
  },
};

const columns = [
  {
    title: "Address",
    dataIndex: "AudioNombre",
    key: "AudioNombre",
  },
];

const { Option } = SelectAntd;

export default () => {
  const { uid } = useParams();
  const { loading, error, data } = useQuery(getProducers);
  const { loading: loadingSong, error: errorSong, data: dataSong } = useQuery(GetSong, {
    fetchPolicy: "network-only",
    variables: { Id: uid },
  });

  const location = useLocation();

  const { loading: loadingGenres, error: errorGenres, data: dataGenres } = useQuery(Genres);
  const { loading: loadingMods, error: errorMods, data: dataMods } = useQuery(Mods);
  const { loading: loadingInstruments, error: errorInstruments, data: dataInstruments } = useQuery(Instruments);
  const { loading: loadingLanguages, error: errorLanguages, data: dataLanguages } = useQuery(Languages);
  const [updateSong, { loading: loadingMutation, data: updatedSong }] = useMutation(UpdateSong);
  const { data: dataLastsSongs, loading: loadingLastsSongs, error: errorLastsSongs } = useQuery(getLastsSongs, { fetchPolicy: "network-only" });


  const [song, setSong] = useState(null);
  const [invalidFile, setInvalidFile] = useState(false);
  const [lastsSongs, setLastsSongs] = useState(null);
  const [genres, setGenres] = useState([]);
  const [songInfo, setSongInfo] = useState(null);
  const [instruments, setInstruments] = useState([]);
  const [moods, setMoods] = useState([]);
  const [producers, setProducers] = useState([]);
  const [languages, setLanguages] = useState([]);
  const history = useHistory();
  const [audioFile, setAudioFile] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [genresSelecteds, setGenresSelecteds] = useState([]);
  const [themesSelecteds, setThemesSelecteds] = useState([]);
  const [instrumentsSelecteds, setInstrumentsSelecteds] = useState([]);
  const [displayInput, setDisplayInput] = useState(false);
  const [saving, setSaving] = useState(false);

  const updateSong_ = (AudioForm) => {
    setSaving(true);
   
    UpdateSongService(
      uid,
      AudioForm.audio,
      updateSong,
      audioFile,
      genresSelecteds,
      themesSelecteds,
      instrumentsSelecteds
    );
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleEdit = (id) => {
    var win = window.open(
      `${process.env.REACT_APP_ADMIN_URL}song/edit/${id}`,
      "_blank"
    );
    win.focus();
    setIsModalVisible(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    let file = acceptedFiles[0] ? acceptedFiles[0].name.split(".") : [];
    if (file[file.length - 1] !== "mp3") {
      // Todo: Mostrar Modal de archivo incorrecto
      setInvalidFile(true);
    } else {
      setInvalidFile(false);
      setAudioFile(acceptedFiles[0]);
      setInvalidFile(false);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "audio/mpeg",
  });

  useEffect(() => {
    if (!loading && !error && data) {
      const tmpProducers = [];
      data.Producers.forEach((producer) => {
        tmpProducers.push(
          <Option key={producer.Productor}>{producer.Productor}</Option>
        );
      });
      setProducers(tmpProducers);
    }
  }, [loading, data, error]);

  useEffect(() => {
    if ((dataLastsSongs, !loadingLastsSongs && !errorLastsSongs)) {
      let result = [...dataLastsSongs.LastsSongs];
      setLastsSongs(result);
    }
  }, [dataLastsSongs, loadingLastsSongs, errorLastsSongs]);

  useEffect(() => {
    if (!loadingMods && dataMods && !errorMods) {
      // const tmpMoods = [];
      // dataMods.Animos.forEach((animo) => {
      //   tmpMoods.push(<Option key={animo.Animo}>{animo.Animo}</Option>);
      // });
      // setMoods(tmpMoods);
      setMoods(dataMods.Animos);
    }
  }, [loadingMods, dataMods, errorMods]);

  useEffect(() => {
    if (!loadingInstruments && dataInstruments && !errorInstruments) {
      // const tmpInstruments = [];
      // dataInstruments.Instruments.forEach((instrument) => {
      //   tmpInstruments.push(
      //     <Option key={instrument.Instrumento}>{instrument.Instrumento}</Option>
      //   );
      // });
      // setInstruments(tmpInstruments);
      setInstruments(dataInstruments.Instruments);
    }
  }, [loadingInstruments, dataInstruments, errorInstruments]);

  useEffect(() => {
    if (!loadingGenres && dataGenres && !errorGenres) {
      // const tmpGenres = [];
      // dataGenres.Genres.forEach((genre) => {
      //   tmpGenres.push(
      //     // <Option key={[genre.IdGenero, genre.Genero]}>{genre.Genero}</Option>
      //     <Option key={genre.Genero}>{genre.Genero}</Option>
      //   );
      // });
      // setGenres(tmpGenres);
      setGenres(dataGenres.Genres);
    }
  }, [loadingGenres, dataGenres, errorGenres]);

  useEffect(() => {
    if (!loadingLanguages && dataLanguages && !errorLanguages) {
      const tmpLanguages = [];
      dataLanguages.Languages.forEach((language) => {
        tmpLanguages.push(
          // <Option key={[genre.IdGenero, genre.Genero]}>{genre.Genero}</Option>
          <Option key={language.Idioma}>{language.Idioma}</Option>
        );
      });
      setLanguages(tmpLanguages);
    }
  }, [loadingLanguages, dataLanguages, errorLanguages]);

  useEffect(() => {
    if (!loadingSong && !errorSong && dataSong && dataSong.Audio) {
      setSong(dataSong.Audio);
      let genresTmp = dataSong.Audio.AudioGeneros_.split(", ");
      setGenresSelecteds(genresTmp.length ? genresTmp : []);
      let themesTmp = dataSong.Audio.AudioAnimo_.split(", ");
      setThemesSelecteds(themesTmp.length ? themesTmp : []);
      let instrumentsSelecteds = dataSong.Audio.AudioInstrumento_.split(", ");
      setInstrumentsSelecteds( instrumentsSelecteds.length ? instrumentsSelecteds : [] );
    }
  }, [loadingSong, errorSong, dataSong]);

  useEffect(() => {
    if (!loadingMutation && updatedSong) {
      history.push({
        pathname: (location.state && location.state.st) ? "/damagedSongs" : "/songs",
      });
      setSaving(false);
    }
  }, [loadingMutation, updatedSong]);


  if (!song) return <div><Loading /></div>;
  if (errorSong) return <span>Error al obtener el audio</span>;

  const songValues = {
    AudioNombre: song.AudioNombre,
    AudioGeneros: song.AudioGeneros_ ? song.AudioGeneros_.split(", ") : [],
    AudioProducers: song.AudioProductor_ ? song.AudioProductor_.split(", ") : [],
    AudioAnimos: song.AudioAnimo_ ? song.AudioAnimo_.split(", ") : [],
    AudioTempo: song.AudioTempo_ ? song.AudioTempo_.split(", ") : [],
    AudioInstrumento: song.AudioInstrumento_ ? song.AudioInstrumento_.split(", ") : [],
    AudioLanguage: song.AudioIdioma_ ? song.AudioIdioma_.split(", ") : [],
    AudioPublisher: song.AudioPublisher_,
    AudioISRC: song.AudioISRC ? song.AudioISRC : "",
    AudioCompositor: song.AudioCompositor ? song.AudioCompositor : "",
    AudioInterprete: song.AudioInterprete ? song.AudioInterprete : "",
    AudioISWC: song.AudioISWC ? song.AudioISWC : "",
    AudioVoz: song.AudioVoz_ ? song.AudioVoz_.split(", ") : [],
  };

  const handleChangeMultipleGenres = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setGenresSelecteds(value);
  };

  const handleChangeMultipleThemes = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setThemesSelecteds(value);
  };

  const handleChangeMultipleInstruments = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setInstrumentsSelecteds(value);
  };

  return (
    <>
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={updateSong_}
        validateMessages={validateMessages}
        initialValues={{ audio: songValues }}
      >
        <Row>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                borderRadius: "15px",
              }}
            >
              <div style={{ width: "50%", padding: "20px" }}>
                <Form.Item
                  name={["audio", "AudioNombre"]}
                  label="Name"
                  rules={[
                    { required: true, message: "Please input Song name!" },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </div>
              <div style={{ width: "50%", padding: "20px" }}>
                {displayInput && (
                  <div
                    style={{
                      border: "1px dashed",
                      textAlign: "center",
                      padding: "20px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                    {...getRootProps({ className: "dropzone" })}
                  >
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some file here, or click to select file</p>
                  </div>
                )}
                {!audioFile && !invalidFile && (
                  <div>
                    <div style={{ paddingBottom: "8px" }}>
                      <label>Audio</label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ReactAudioPlayer
                        style={{ width: "100%" }}
                        /* src={`localhost:3001/audio/${song.slugUrl}`} */
                        src={process.env.REACT_APP_API_URL+`audio/${song.slugUrl}`}
                        controls
                      />
                      <Button
                        type="primary"
                        style={{ marginLeft: "10px" }}
                        onClick={() => setDisplayInput(true)}
                      >
                        <EditOutlined />
                      </Button>
                    </div>
                  </div>
                )}
                {audioFile && !invalidFile && (
                  <ReactAudioPlayer
                    style={{ width: "100%" }}
                    src={URL.createObjectURL(audioFile)}
                    controls
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={4} style={{ padding: "20px" }}>
            <Form.Item name={["audio", "AudioISRC"]} label="ISRC">
              <Input />
            </Form.Item>
            <Form.Item name={["audio", "AudioCompositor"]} label="Composer">
              <Input />
            </Form.Item>
            <Form.Item name={["audio", "AudioProducers"]} label="Producers">
              <SelectAntd mode="multiple" allowClear style={{ width: "100%" }}>
                {producers}
              </SelectAntd>
            </Form.Item>
            <Form.Item name={["audio", "AudioPublisher"]} label="Publisher">
              <Input />
            </Form.Item>
            <Form.Item name={["audio", "AudioISWC"]} label="ISWC">
              <Input className="inputStyle" />
            </Form.Item>
            <Form.Item name={["audio", "AudioInterprete"]} label="Performer">
              <Input className="inputStyle" />
            </Form.Item>
            <Button
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
              disabled={saving}
            >
              {saving ? <LoadingOutlined /> : <SaveOutlined />}
              Save song
            </Button>
          </Col>
          <Col span={4} style={{ padding: "20px" }}>
            <label>
              Genre{" "}
              <span
                style={{
                  backgroundColor: "#343a40",
                  color: "#fff",
                  paddingLeft: "5px",
                  padddingRight: "5px",
                }}
              >
                (CTRL + Clic)
              </span>
            </label>
            <div style={{ marginBottom: "20px", marginTop: "5px" }}>
              {genresSelecteds.map((g) => (
                <Tag color="blue">{g}</Tag>
              ))}
            </div>
            <Select
              multiple
              native
              style={{ width: "100%", height: "90%" }}
              value={genresSelecteds}
              onChange={handleChangeMultipleGenres}
              inputProps={{
                id: "select-multiple-native",
              }}
            >
              {genres.map((genre, index) => (
                <option key={genre.IdGenero} value={genre.Genero}>
                  {genre.Genero}
                </option>
              ))}
            </Select>
          </Col>
          <Col span={4} style={{ padding: "20px" }}>
            <label>
              Theme{" "}
              <span
                style={{
                  backgroundColor: "#343a40",
                  color: "#fff",
                  paddingLeft: "5px",
                  padddingRight: "5px",
                }}
              >
                (CTRL + Clic)
              </span>
            </label>
            <div style={{ marginBottom: "20px", marginTop: "5px" }}>
              {themesSelecteds.map((t) => (
                <Tag color="blue">{t}</Tag>
              ))}
            </div>
            <Select
              multiple
              native
              style={{ width: "100%", height: "90%" }}
              value={themesSelecteds}
              onChange={handleChangeMultipleThemes}
              inputProps={{
                id: "select-multiple-native",
              }}
            >
              {moods.map((mod, index) => (
                <option key={mod.IdAnimo} value={mod.Animo}>
                  {mod.Animo}
                </option>
              ))}
            </Select>
          </Col>
          <Col span={4} style={{ padding: "20px" }}>
            <label>
              Instruments{" "}
              <span
                style={{
                  backgroundColor: "#343a40",
                  color: "#fff",
                  paddingLeft: "5px",
                  padddingRight: "5px",
                }}
              >
                (CTRL + Clic)
              </span>
            </label>
            <div style={{ marginBottom: "20px", marginTop: "5px" }}>
              {instrumentsSelecteds.map((t) => (
                <Tag color="blue">{t}</Tag>
              ))}
            </div>
            <Select
              multiple
              native
              style={{ width: "100%", height: "90%" }}
              value={instrumentsSelecteds}
              onChange={handleChangeMultipleInstruments}
              inputProps={{
                id: "select-multiple-native",
              }}
            >
              {instruments.map((instrument, index) => (
                <option
                  key={instrument.IdInstrumento}
                  value={instrument.Instrumento}
                >
                  {instrument.Instrumento}
                </option>
              ))}
            </Select>
          </Col>
          <Col span={4} style={{ padding: "20px" }}>
            <Form.Item name={["audio", "AudioLanguage"]} label="Languages">
              <SelectAntd mode="multiple" allowClear style={{ width: "100%" }}>
                {languages}
              </SelectAntd>
            </Form.Item>
            <Form.Item name={["audio", "AudioVoz"]} label="Voice">
              <SelectAntd mode="multiple" allowClear style={{ width: "100%" }}>
                <Option key="Masculina">Male</Option>
                <Option key="Femenina">Female</Option>
                <Option key="Ambas">Both</Option>
                <Option key="Instrumental">Instrumental</Option>
              </SelectAntd>
            </Form.Item>
            <Form.Item name={["audio", "AudioTempo"]} label="Tempo">
              <SelectAntd
                mode="multiple"
                className="inputStyle"
                dropdownClassName="dropdownStyle"
                allowClear
                style={{ width: "100%" }}
              >
                <Option key="Lento">Lento</Option>
                <Option key="Medio">Medio</Option>
                <Option key="Rápido">Rápido</Option>
              </SelectAntd>
            </Form.Item>
            {/* <Button type="primary" htmlType="submit">
              Save song
            </Button> */}
          </Col>
          <Col span={4} style={{ padding: "20px" }}>
            <div className="SongsRelateds">
              {lastsSongs ? (
                <div>
                  <Table
                    rowClassName="rowLast"
                    className="rels"
                    showHeader={false}
                    columns={columns}
                    dataSource={lastsSongs}
                    pagination={false}
                    title={() => <b>Last added Songs</b>}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          setSongInfo(record);
                          setIsModalVisible(true);
                        },
                      };
                    }}
                  />
                </div>
              ) : (
                <Loading />
              )}
            </div>
          </Col>
        </Row>
      </Form>

      <Modal
        title={songInfo ? songInfo.AudioNombre : "---"}
        visible={isModalVisible}
        onCancel={handleOk}
        onOk={() => handleEdit(songInfo.ID)}
        okText="Edit Song"
        cancelText="Close"
      >
        {songInfo ? (
          <div>
            <Row>
              <Col span={12}>
                <Form.Item label="Name">
                  {songInfo.AudioNombre ? songInfo.AudioNombre : "---"}
                </Form.Item>
                <Form.Item label="Genres">
                  {songInfo.AudioGeneros_
                    ? songInfo.AudioGeneros_.split(", ").map((text, index) => (
                        <Tag color="blue" style={{ margin: "1px" }} key={index}>
                          {text}
                        </Tag>
                      ))
                    : "---"}
                </Form.Item>
                <Form.Item label="Themes">
                  {songInfo.AudioAnimo_
                    ? songInfo.AudioAnimo_.split(", ").map((text, index) => (
                        <Tag color="blue" style={{ margin: "1px" }} key={index}>
                          {text}
                        </Tag>
                      ))
                    : "---"}
                </Form.Item>
                <Form.Item label="Instruments">
                  {songInfo.AudioInstrumento_
                    ? songInfo.AudioInstrumento_.split(", ").map(
                        (text, index) => (
                          <Tag
                            color="blue"
                            style={{ margin: "1px" }}
                            key={index}
                          >
                            {text}
                          </Tag>
                        )
                      )
                    : "---"}
                </Form.Item>
                <Form.Item label="Languages">
                  {songInfo.AudioIdioma_
                    ? songInfo.AudioIdioma_.split(", ").map((text, index) => (
                        <Tag color="blue" style={{ margin: "1px" }} key={index}>
                          {text}
                        </Tag>
                      ))
                    : "---"}
                </Form.Item>
                <Form.Item label="Tempos">
                  {songInfo.AudioTempo_
                    ? songInfo.AudioTempo_.split(", ").map((text, index) => (
                        <Tag color="blue" style={{ margin: "1px" }} key={index}>
                          {text}
                        </Tag>
                      ))
                    : "---"}
                </Form.Item>
                <Form.Item label="ISRC">
                  {songInfo.AudioISRC ? songInfo.AudioISRC : "---"}
                </Form.Item>
                <Form.Item label="Composer">
                  {songInfo.AudioCompositor ? songInfo.AudioCompositor : "---"}
                </Form.Item>
                <Form.Item label="Publisher">
                  {songInfo.AudioPublisher_ ? songInfo.AudioPublisher_ : "---"}
                </Form.Item>
                <Form.Item label="Publisher">
                  {songInfo.AudioProductor_ ? songInfo.AudioProductor_ : "---"}
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
          <Loading />
        )}
      </Modal>
    </>
  );
};
